import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./index.css";
// import 'tailwindcss/dist/tailwind.css';
import VideoCall from "./components/VideoCall/Video";
import Validator from "./components/VideoCall/Validator/Validator";
import StudentEnd from "./components/VideoCall/CallEndPages/StudentEnd";
import InterviewerEnd from "./components/VideoCall/CallEndPages/InterviewerEnd";
import InterviewerMidEnd from "./components/VideoCall/MidEndPages/InterviewerMidEnd";
import StudentMidEnd from "./components/VideoCall/MidEndPages/StudentMidEnd";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ErrorBoundary } from "react-error-boundary";

import ZoomVideo from "@zoom/videosdk";
import { ZoomContext, ZoomUserContext } from "./components/ZoomCall/Context/globalContext";
import { devConfig } from "./components/ZoomCall/Config/dev";

const meetingArgs = { ...devConfig };
// const getToken = async (options) => {
//   let result;
//   result = await fetch(
//     "https://backend.interwiu.com/zoom/generate-sdk-token",
//     options
//   );
//   result = await result.json();

//   return result;
// };

// const getContext = async (options) => {
//   let result;
//   result = await fetch(
//     "https://backend.interwiu.com/zoom/get-sdk-context",
//     options
//   );
//   result = await result.json();

//   return result;
// };
// if (meetingArgs.topic === "") {
//   const requestOptions = {
//     method: "GET",
//     headers: { "Content-Type": "application/json" },
//   };
//   await getContext(requestOptions).then((res) => {
//     res = JSON.parse(res);
//     meetingArgs.topic = res.topic;
//     meetingArgs.name = res.name;
//     meetingArgs.password = res.password;
//   });
//   if (!meetingArgs.signature && meetingArgs.topic !== "") {
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(meetingArgs),
//     };
//     await getToken(requestOptions).then((res) => {
//       meetingArgs.signature = res;
//     });
//     console.log(meetingArgs);
//   }
// }
if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
  console.log = function () { };
}


const client = ZoomVideo.createClient();

function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  console.log(error)
  // setTimeout(() => {

  // }, 1000)
  useEffect(() => {
    window.location.reload()
  }, [])

  // resetErrorBoundary()
  return (
    <div role="alert" className="h-screen w-full flex justify-content justify">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StyledEngineProvider injectFirst>
  // <ErrorBoundary FallbackComponent={Fallback}>
  <ZoomContext.Provider value={client}>
    <ZoomUserContext.Provider value={meetingArgs}>
      <BrowserRouter>
        <Routes>
          {/* VIDEOCALL PATHS */}
          {/* <Route path="/valid/vc/:mock_id/:id/:name" element={<Validator />} />
          <Route path="/vc/:mock_id/:id/:name" element={<VideoCall />} />
          <Route path="/end/student/vc/:mock_id" element={<StudentEnd />} />
          <Route path="/end/interviewer/vc/:mock_id" element={<InterviewerEnd />} />
          <Route path="/midend/student/vc/:mock_id" element={<StudentMidEnd />} />
          <Route path="/midend/interviewer/vc/:mock_id" element={<InterviewerMidEnd />}/> */}
          <Route path="/*" element={<App />} />
        </Routes>
        {/* APP PATHS */}

        {/* <App /> */}
        <Toaster

          position="top-right"
          toastOptions={{ className: "react-hot-toast" }}
        />
      </BrowserRouter>
    </ZoomUserContext.Provider>
  </ZoomContext.Provider>
  // </ErrorBoundary>
  // </StyledEngineProvider>
);
