import React from "react";
import { useNavigate } from "react-router-dom";
import "./SignupEmployer.css";
import { MdEmail } from "react-icons/md";

const remove = () => {
    const loginFunc = document.querySelector(".signup-employer");
    loginFunc.classList.add("hide");
};

function childClick(event) {
    // console.log("Child clicked");
    event.stopPropagation(); // stop propagation to parent
}


const SignupEmployer = () => {
    const navigate = useNavigate();

    const handleEmpManual = (e) => {
        e.preventDefault();

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });

        // Navigate to the new route
        navigate("/emp/register");
    };

    return (
        <div className="signup-employer hide" style={{ backdropFilter: "blur(10px)", paddingTop: "70px" }} onClick={() => {
            remove();
        }}>
            <div className="login" style={{ margin: "10px", minHeight: "50vh" }} onClick={(e) => childClick(e)}>
                <div
                    className="cross"
                    onClick={() => {
                        remove();
                    }}
                >
                    <i className="fa-solid fa-circle-xmark"></i>
                </div>
                <div className="login-head login-head-2">
                    <p
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            lineHeight: "1.2",
                            backgroundColor: "#E4EDF3",
                            padding: "20px",
                            borderRadius: "5px",
                        }}
                    >
                        <span>
                            {/* Let’s appear for a Mock Interview and obtain Feedback on your
                            performance in realtime! */}
                        </span>
                        <span style={{ fontWeight: "bold", marginTop: "10px" }}>
                            Create an Account now!
                        </span>
                    </p>
                </div>
                <div className="login-system">
                    <div className="col">
                        <h1>Create an Account as an Employer</h1>
                        {/* <GoogleLogin
              clientId="752367686296-0scm5me6nrjuc64f9dc36i88c7mstjrt.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseSuccessGoogleStd}
              onFailure={responseErrorGoogleStd}
              cookiePolicy={'single_host_origin'}
            /> */}
                        <form onSubmit={(e) => handleEmpManual(e)}>
                            <button className="signin-btn login" type="submit">
                                <i style={{ marginRight: "10px", marginLeft: "-10px", fontSize: "18px", color: "gray" }}><MdEmail /></i>
                                Create an Account with Email
                            </button>
                        </form>
                    </div>
                    <div className="login-image col">
                        <img src={require("../../images/group3.png")} alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupEmployer;
