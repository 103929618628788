import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Container,
    Paper,
    Box,
    Alert,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Divider
} from '@mui/material';
import axios from "axios"
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Business, Person, Check } from '@mui/icons-material';
import UserContext from '../../../contexts/UserContext';
import { useEffect } from 'react';
import "./Verification.css"

const VerificationForm = () => {
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER_URL;
    const [activeStep, setActiveStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        mobileWhatsApp: '',
        website: '',
        linkedInCompany: '',
        linkedInRep: '',
    });
    const { user, setUser } = useContext(UserContext);

    const handleChange = (e) => {
        // If it's a direct value from PhoneInput
        console.log(e, "****");

        if (!e.target) {
            setFormData(prev => ({
                ...prev,
                mobileWhatsApp: e // e is the actual value here
            }));
        }
        // If it's a regular input event
        else if (e && e.target) {
            const { name, value } = e.target;
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    // useEffect(() => {
    //     if (user.companyVerifiedStatus === "verified") {
    //         navigate("/emp/create-job");
    //     }
    // }, [user.companyVerifiedStatus, navigate]);
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            email: user.email
        }))
        const fetchRequest = async () => {
            if (user?.email) {

                try {
                    const response = await axios.post(`${BACKEND_URL}/emp/getRequest`, { email: user.email })
                    if (response.status === 200) {
                        console.log(response.data);
                        const { companyName, email, mobileWhatsApp, website, linkedInCompany, linkedInRep } = response.data.employerrequest;

                        setFormData({
                            companyName,
                            email,
                            mobileWhatsApp,
                            website,
                            linkedInCompany,
                            linkedInRep,
                        });
                    }
                } catch (error) {
                    console.log("Error ", error);
                }

            }
        }
        fetchRequest();
    }, [user.companyVerifiedStatus, user])

    const handleNext = (e) => {
        e.preventDefault(); // Prevent form submission on next
        if (isStepValid(activeStep)) {
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Only allow submission from the review step
        if (activeStep !== steps.length - 1) {
            handleNext(e);
            return;
        }

        setIsSubmitting(true);
        try {
            await axios.post(`${BACKEND_URL}/emp/expressInterest`, formData)
                .then(res => {
                    console.log("Response", res);
                    const data = res.data;

                    setUser({
                        ...user,
                        companyVerifiedStatus: data.user.companyVerifiedStatus
                    });
                })
                .catch(e => {
                    console.error("Error", e);
                });


        } catch (error) {
            console.error('There was an error submitting the form:', error);
            alert('Failed to submit. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const steps = ['Company Information', 'Representative Details', 'Review & Submit'];

    const isStepValid = (step) => {
        switch (step) {
            case 0:
                return formData.companyName && formData.website;
            case 1:
                return formData.email && formData.mobileWhatsApp && formData.linkedInRep;
            default:
                return true;
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box className="space-y-6">
                        <div className="flex items-center space-x-2 mb-6">
                            <Business color="primary" />
                            <Typography variant="h6" component="h3" className="font-semibold text-gray-700">
                                Company Details
                            </Typography>
                        </div>
                        <TextField
                            fullWidth
                            required
                            label="Name of Company"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            variant="outlined"
                            className="mb-4"
                        />
                        <TextField
                            fullWidth
                            required
                            label="Company Website"
                            name="website"
                            type="url"
                            value={formData.website}
                            onChange={handleChange}
                            variant="outlined"
                            className="mb-4"
                        />
                        <TextField
                            fullWidth
                            label="Company LinkedIn Page (Optional)"
                            name="linkedInCompany"
                            type="url"
                            value={formData.linkedInCompany}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Box>
                );
            case 1:
                return (
                    <Box className="space-y-6">
                        <div className="flex items-center space-x-2 mb-6">
                            <Person color="primary" />
                            <Typography variant="h6" component="h3" className="font-semibold text-gray-700">
                                Representative Details
                            </Typography>
                        </div>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Email/Gmail Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            variant="outlined"
                            className="mb-4"
                        />

                        <PhoneInput
                            country={'in'}
                            value={formData.mobileWhatsApp}
                            onChange={handleChange}
                            inputClass="form-control"
                            containerClass="phone-input"
                            specialLabel="Contact Number"
                            enableSearch={true}
                            searchPlaceholder={'Search'}
                            inputProps={{
                                required: true,
                            }}
                            inputStyle={{
                                width: '100%',
                                height: '56px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                backgroundColor: 'transparent',
                                // paddingLeft: '48px',
                                paddingTop: '0',
                                paddingBottom: '0',
                                // marginBottom: '24px'
                            }}
                            buttonStyle={{
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                borderRight: 'none',
                                backgroundColor: 'transparent',
                                borderRadius: '4px 0 0 4px'
                            }}
                            containerStyle={{
                                width: '100%'
                            }}
                            dropdownClass={'mobile-dropdown-emp'}
                            labels={{ required: true }} // Shows the asterisk for required field
                        />
                        <TextField
                            fullWidth
                            required
                            label="LinkedIn URL"
                            name="linkedInRep"
                            type="url"
                            value={formData.linkedInRep}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box className="space-y-6">
                        <div className="flex items-center space-x-2 mb-6">
                            <Check color="primary" />
                            <Typography variant="h6" component="h3" className="font-semibold text-gray-700">
                                Review Your Information
                            </Typography>
                        </div>
                        <Alert severity="info" className="mb-4">
                            Please review your information carefully before submitting.
                        </Alert>
                        <div className="bg-gray-50 p-6 rounded-lg">
                            <Typography variant="subtitle1" className="font-semibold mb-2">Company Details</Typography>
                            <div className="grid grid-cols-2 gap-4 mb-6">
                                <div>
                                    <Typography variant="caption" className="text-gray-600">Company Name</Typography>
                                    <Typography>{formData.companyName}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption" className="text-gray-600">Website</Typography>
                                    <Typography>{formData.website}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                    <Typography>{formData.linkedInCompany || 'Not provided'}</Typography>
                                </div>
                            </div>
                            <Divider className="my-4" />
                            <Typography variant="subtitle1" className="font-semibold mb-2">Representative Details</Typography>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <Typography variant="caption" className="text-gray-600">Email</Typography>
                                    <Typography>{formData.email}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption" className="text-gray-600">Mobile/WhatsApp</Typography>
                                    <Typography>{formData.mobileWhatsApp}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                    <Typography>{formData.linkedInRep}</Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderContent = () => {
        switch (user.companyVerifiedStatus) {
            case "verified":
                return (<Container maxWidth="md" className="py-8">
                    <Paper elevation={3} className="p-8">
                        <Box className="text-center mb-8">
                            {/* <CircularProgress size={60} className="mb-4" /> */}
                            <Typography variant="h4" component="h2" className="font-bold text-gray-800">
                                Verification Completed
                            </Typography>
                        </Box>
                        <Alert
                            severity="success"
                            className="my-4"
                            sx={{
                                '& .MuiAlert-message': {
                                    fontSize: '1.1rem',
                                    textAlign: 'center',
                                    width: '100%'
                                }
                            }}
                        >
                            Your company has been verified successfully!
                        </Alert>
                        <Box>
                            <div className="bg-gray-50 p-6 rounded-lg">
                                <Typography variant="subtitle1" className="font-semibold mb-2">Company Details</Typography>
                                <div className="grid grid-cols-2 gap-4 mb-6">
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">Company Name</Typography>
                                        <Typography>{formData.companyName}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">Website</Typography>
                                        <Typography>{formData.website}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                        <Typography>{formData.linkedInCompany || 'Not provided'}</Typography>
                                    </div>
                                </div>
                                <Divider className="my-4" />
                                <Typography variant="subtitle1" className="font-semibold mb-2">Representative Details</Typography>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">Email</Typography>
                                        <Typography>{formData.email}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">Mobile/WhatsApp</Typography>
                                        <Typography>{formData.mobileWhatsApp}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                        <Typography>{formData.linkedInRep}</Typography>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Paper>
                </Container>);
            case "pending":
                return (
                    <Container maxWidth="md" className="py-8">
                        <Paper elevation={3} className="p-8">
                            <Box className="text-center mb-8">
                                {/* <CircularProgress size={60} className="mb-4" /> */}
                                <Typography variant="h4" component="h2" className="font-bold text-gray-800">
                                    Verification in Progress
                                </Typography>
                            </Box>
                            <Alert
                                severity="info"
                                className="my-4"
                                sx={{
                                    '& .MuiAlert-message': {
                                        fontSize: '1.1rem',
                                        textAlign: 'center',
                                        width: '100%'
                                    }
                                }}
                            >
                                Your company verification request is currently under review. You will be notified of the
                                verification status via your registered email address. Thank you for your patience.
                            </Alert>
                            <Box>
                                <div className="bg-gray-50 p-6 rounded-lg">
                                    <Typography variant="subtitle1" className="font-semibold mb-2">Company Details</Typography>
                                    <div className="grid grid-cols-2 gap-4 mb-6">
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">Company Name</Typography>
                                            <Typography>{formData.companyName}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">Website</Typography>
                                            <Typography>{formData.website}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                            <Typography>{formData.linkedInCompany || 'Not provided'}</Typography>
                                        </div>
                                    </div>
                                    <Divider className="my-4" />
                                    <Typography variant="subtitle1" className="font-semibold mb-2">Representative Details</Typography>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">Email</Typography>
                                            <Typography>{formData.email}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">Mobile/WhatsApp</Typography>
                                            <Typography>{formData.mobileWhatsApp}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="caption" className="text-gray-600">LinkedIn</Typography>
                                            <Typography>{formData.linkedInRep}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </Container>
                );
            default:
                return (
                    <Container maxWidth="md" className="py-8">
                        <Paper elevation={3} className="p-8">
                            <Typography variant="h4" component="h2" className="mb-6 text-center font-bold text-gray-800">
                                Startup Verification Form
                            </Typography>

                            <Alert
                                severity="info"
                                className="mb-8"
                                sx={{ '& .MuiAlert-message': { fontSize: '1rem' } }}
                            >
                                You are currently not authorized to create a startup/company account on interwiu.com.
                                To request access, please express your interest in hiring employees or interns through interwiu.com by submitting the form below.
                            </Alert>

                            <Stepper activeStep={activeStep} className="mb-8">
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                {renderStepContent(activeStep)}

                                <Box className="flex justify-between mt-8">
                                    <Button
                                        onClick={handleBack}
                                        disabled={activeStep === 0}
                                        variant="outlined"
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || !isStepValid(activeStep)}
                                        className="px-8"
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress size={24} className="mr-2" />
                                        ) : activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </Container>
                );
        }
    };

    return renderContent();
};

export default React.memo(VerificationForm);