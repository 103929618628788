import React, { useState, useContext } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import UserContext from "../../../contexts/UserContext";

// Define routes - you can move these to your routes constants file
const EMPLOYER_ROUTES = {
    HOME_ROUTE: "/",
    CREATE_JOB_POST_ROUTE: "/emp/create-job",
    ACTIVE_JOBS_ROUTE: "/emp/active-jobs",
    CLOSED_JOBS_ROUTE: "/emp/closed-jobs",
    TOP_PERFORMERS_ROUTE: "/emp/top-performers",
    EMPLOYER_DASHBOARD_ROUTE: "/emp/dashboard",
    EMPLOYER_SETTINGS_ROUTE: "/emp/settings",
    EMPLOYER_REPORTS_ROUTE: "/emp/reports",
    EMPLOYER_VERIFICATION_ROUTE: "/emp/verify"
};

const NavbarEmployer = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const location = useLocation();

    // Profile Dropdown State
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loggingOut = () => {
        if (localStorage.getItem("isEAuthenticated")) {
            localStorage.removeItem("isEAuthenticated");
        }
        setUser({});
        navigate(EMPLOYER_ROUTES.HOME_ROUTE);
    };

    const handleUnverifiedClick = (e) => {
        if (user.companyVerifiedStatus !== "verified") {
            e.preventDefault();
            // You can add a toast notification here if needed
        }
    };

    return (
        <>
            <div className="navbarlogedin flex">
                <div className="logo">
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <h1 className="text-2xl font-medium">interwiu.com</h1>
                    </Link>
                </div>

                <ul className="nav-list flex">
                    <li>
                        <NavLink
                            end
                            className={`nav-item hover-underline-animation ${user.companyVerifiedStatus !== "verified" ? 'opacity-50 pointer-events-none' : ''}`}
                            activeClassName="active"
                            to={EMPLOYER_ROUTES.CREATE_JOB_POST_ROUTE}
                            onClick={handleUnverifiedClick}
                        >
                            <div>Create Job Post</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            end
                            className={`nav-item hover-underline-animation ${user.companyVerifiedStatus !== "verified" ? 'opacity-50 pointer-events-none' : ''}`}
                            activeClassName="active"
                            to={EMPLOYER_ROUTES.ACTIVE_JOBS_ROUTE}
                            onClick={handleUnverifiedClick}
                        >
                            <div className="flex">
                                Active Job Posts
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            end
                            className={`nav-item hover-underline-animation ${user.companyVerifiedStatus !== "verified" ? 'opacity-50 pointer-events-none' : ''}`}
                            activeClassName="active"
                            to={EMPLOYER_ROUTES.CLOSED_JOBS_ROUTE}
                            onClick={handleUnverifiedClick}
                        >
                            <div>Closed Job Posts</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            end
                            className={`nav-item hover-underline-animation ${user.companyVerifiedStatus !== "verified" ? 'opacity-50 pointer-events-none' : ''}`}
                            activeClassName="active"
                            to={EMPLOYER_ROUTES.TOP_PERFORMERS_ROUTE}
                            onClick={handleUnverifiedClick}
                        >
                            <div className="flex">
                                Top Performers
                            </div>
                        </NavLink>
                    </li>
                </ul>

                <div className="sidebar flex">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Tooltip title="My Profile">
                            <Button
                                onClick={handleClick}
                                size="small"
                                sx={{ color: "black" }}
                                aria-controls={open ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                            >
                                <div className="sidebar-profile px-2 rounded border cursor-pointer hover:bg-slate-200 flex">
                                    <div className="sidebar-profile-img mx-1 flex">
                                        <img
                                            src={user.photo}
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="text-sm mx-1 flex"
                                        style={{ flexDirection: "column" }}
                                    >
                                        <p>Me</p>
                                        <i className="fa-solid fa-caret-down"></i>
                                    </div>
                                </div>
                            </Button>
                        </Tooltip>
                    </Box>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1,
                                px: 1,
                                width: 300,
                                borderRadius: 2,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <Typography>
                            <div className="profile-dropdown-head flex">
                                <div>
                                    <img
                                        src={user.photo}
                                        style={{
                                            height: "60px",
                                            width: "60px",
                                            borderRadius: "50%",
                                        }}
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="h5"
                                        component="h5"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {user.fname + (user.lname ? " " + user.lname : "")}
                                    </Typography>
                                    <p>{user.company}</p>
                                </div>
                            </div>
                        </Typography>
                        <button
                            type="button"
                            className="cust-btn view-profile-btn"
                            onClick={() => {
                                navigate(EMPLOYER_ROUTES.EMPLOYER_DASHBOARD_ROUTE);
                            }}
                        >
                            View Profile
                        </button>
                        <Divider />
                        <MenuItem
                            id="dropdown-btn"
                            onClick={() => {
                                if (user.companyVerifiedStatus) {
                                    navigate(EMPLOYER_ROUTES.EMPLOYER_REPORTS_ROUTE);
                                }
                            }}
                            style={{
                                background: `${location.pathname.split("/").pop() === 'reports' ? 'rgb(196, 240, 255)' : ''}`,
                                color: `${location.pathname.split('/').pop() === 'reports' ? 'black' : ''}`,
                                opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1,
                                pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto'
                            }}
                        >
                            <p>
                                <i className="fa-solid fa-chart-line"></i> Analytics & Reports
                            </p>
                        </MenuItem>
                        <MenuItem
                            id="dropdown-btn"
                            onClick={() => {
                                if (user.companyVerifiedStatus) {
                                    navigate(EMPLOYER_ROUTES.EMPLOYER_SETTINGS_ROUTE);
                                }
                            }}
                            style={{
                                background: `${location.pathname.split("/").pop() === 'settings' ? 'rgb(196, 240, 255)' : ''}`,
                                color: `${location.pathname.split('/').pop() === 'settings' ? 'black' : ''}`,
                                opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1,
                                pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto'
                            }}
                        >
                            <p>
                                <i className="fa-solid fa-gear"></i> Company Settings
                            </p>
                        </MenuItem>
                        <MenuItem
                            id="dropdown-btn"
                            onClick={() => {
                                if (user.companyVerifiedStatus) {
                                    navigate(EMPLOYER_ROUTES.EMPLOYER_VERIFICATION_ROUTE);
                                }
                            }}
                            style={{
                                background: `${location.pathname.split("/").pop() === 'settings' ? 'rgb(196, 240, 255)' : ''}`,
                                color: `${location.pathname.split('/').pop() === 'settings' ? 'black' : ''}`,
                                opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1,
                                pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto'
                            }}
                        >
                            <p>
                                <i className="fa-solid fa-check-circle"></i> Verification Status
                            </p>
                        </MenuItem>
                        <Divider />
                        <button className="cust-btn logout-btn" onClick={loggingOut}>
                            <i className="fa-solid fa-arrow-right-from-bracket"></i> Log Out
                        </button>
                    </Menu>

                    {/* Mobile Menu Icon */}
                    <IconButton
                        aria-label="more"
                        className="navLoggedInDropdownIcon"
                        id="long-button"
                        aria-controls={Boolean(anchorEl2) ? "long-menu" : undefined}
                        aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl2(e.currentTarget)}
                    >
                        <MenuRoundedIcon />
                    </IconButton>

                    {/* Mobile Menu */}
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl2}
                        open={Boolean(anchorEl2)}
                        onClose={() => setAnchorEl2(null)}
                    >
                        <NavLink
                            to={EMPLOYER_ROUTES.CREATE_JOB_POST_ROUTE}
                            onClick={handleUnverifiedClick}
                            style={{ pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto' }}
                        >
                            <MenuItem
                                onClick={() => { setAnchorEl2(null) }}
                                style={{
                                    background: `${location.pathname.split("/").pop() === 'create-job' ? 'rgb(196, 240, 255)' : ''}`,
                                    color: `${location.pathname.split('/').pop() === 'create-job' ? 'black' : ''}`,
                                    opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1
                                }}
                            >
                                <div>Create Job Post</div>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={EMPLOYER_ROUTES.ACTIVE_JOBS_ROUTE}
                            onClick={handleUnverifiedClick}
                            style={{ pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto' }}
                        >
                            <MenuItem
                                onClick={() => { setAnchorEl2(null) }}
                                style={{
                                    background: `${location.pathname.split("/").pop() === 'active-jobs' ? 'rgb(196, 240, 255)' : ''}`,
                                    color: `${location.pathname.split('/').pop() === 'active-jobs' ? 'black' : ''}`,
                                    opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1
                                }}
                            >
                                <div>Active Job Posts</div>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={EMPLOYER_ROUTES.CLOSED_JOBS_ROUTE}
                            onClick={handleUnverifiedClick}
                            style={{ pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto' }}
                        >
                            <MenuItem
                                onClick={() => { setAnchorEl2(null) }}
                                style={{
                                    background: `${location.pathname.split("/").pop() === 'closed-jobs' ? 'rgb(196, 240, 255)' : ''}`,
                                    color: `${location.pathname.split('/').pop() === 'closed-jobs' ? 'black' : ''}`,
                                    opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1
                                }}
                            >
                                <div>Closed Job Posts</div>
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={EMPLOYER_ROUTES.TOP_PERFORMERS_ROUTE}
                            onClick={handleUnverifiedClick}
                            style={{ pointerEvents: user.companyVerifiedStatus !== "verified" ? 'none' : 'auto' }}
                        >
                            <MenuItem
                                onClick={() => { setAnchorEl2(null) }}
                                style={{
                                    background: `${location.pathname.split("/").pop() === 'top-performers' ? 'rgb(196, 240, 255)' : ''}`,
                                    color: `${location.pathname.split('/').pop() === 'top-performers' ? 'black' : ''}`,
                                    opacity: user.companyVerifiedStatus !== "verified" ? 0.5 : 1
                                }}
                            >
                                <div>Top Performers</div>
                            </MenuItem>
                        </NavLink>
                    </Menu>
                </div>
            </div>
        </>
    );
};

export default NavbarEmployer;