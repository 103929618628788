import React from "react";
import { useState } from "react";
import Signupstudent from "./Signupstudent";
import Signupprofessional from "./Signupprofessional";
import Signupadmin from "./SignupAdmin";
import SignupEmployer from "./SignUpEmployer";

const GetStarted = () => {
  const [signupAsStudentOpen, setSignupAsStudentOpen] = useState(false);
  const [signupAsProfessionalOpen, setSignupAsProfessionalOpen] =
    useState(false);
  return (
    <div>
      {/* Signup */}
      <Signupstudent />
      <Signupprofessional />
      <Signupadmin />
      {process.env.REACT_APP_HIRING_MODULE_ACTIVATED === "TRUE" && <SignupEmployer />}
      {/* {signupAsStudentOpen ? <Signupstudent /> : null}
        {signupAsProfessionalOpen ? <Signupprofessional /> : null} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        <div
          className="flex text-center"
          style={{
            padding: "70px 10px 10px 10px",
            maxWidth: "1080px",
            gap: "40px",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              width: "clamp(300px, 30vw, 400px)",
              backgroundColor: "rgb(255 255 255)",
              borderRadius: "10px",
              height: "266px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "17px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"

            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600"
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  padding: "5px 10px",
                  color: "hsl(0%, 0%, 15%)",
                  marginBottom: "20px"
                }}
              >
                Are you a Student?
              </span>
              <span style={{ color: "hsl(0%, 0%, 15%)", padding: "0px 20px" }}>
                Looking for a Mock Interview {"(with Live Feedback)"}?
              </span>
            </p>
            <button
              style={{ fontSize: "18px", marginTop: "20px" }}
              className="cust-btn create-account-btn"
              onClick={() => {
                const signupstd = document.querySelector(".signup-student");
                signupstd.classList.remove("hide");
              }}
            >
              Create Account
            </button>
          </div>
          {/* <div className="border-l-2 border-black h-40" style={{height: "11rem"}}></div> */}
          <div
            style={{
              width: "clamp(300px, 30vw, 400px)",
              backgroundColor: "rgb(255 255 255)",
              borderRadius: "10px",
              height: "266px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "17px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"

            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600"
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  padding: "5px 10px",
                  // backgroundColor: "#E2E2E2",
                  // borderRadius: "50px",
                  color: "hsl(0% 0% 20%)",
                  marginBottom: "20px"
                }}
              >
                Are you a Professional?
              </span>
              <span style={{ color: "hsl(0%, 0%, 15%)", padding: "0px 20px" }}>Offering a Mock Interview {"(with Live Feedback)"}?</span>
            </p>
            <button
              className="cust-btn create-account-btn"
              style={{ fontSize: "18px", marginTop: "20px" }}
              onClick={() => {
                const signuppro = document.querySelector(
                  ".signup-professional"
                );
                signuppro.classList.remove("hide");
              }}
            >
              Create Account
            </button>
          </div>
          <div
            style={{
              width: "clamp(300px, 30vw, 400px)",
              backgroundColor: "rgb(255 255 255)",
              borderRadius: "10px",
              height: "266px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "17px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"

            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600"
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  padding: "5px 10px",
                  color: "hsl(0% 0% 20%)",
                  // backgroundColor: "#E2E2E2",
                  // borderRadius: "50px",
                  marginBottom: "20px"
                }}
              >
                Are you an Institute Admin?
              </span>
              <span style={{ color: "hsl(0%, 0%, 15%)", padding: "0px 20px" }}>Log In as Institute Admin</span>
            </p>
            <button
              style={{ fontSize: "18px", marginTop: "20px" }}
              className="cust-btn create-account-btn"
              onClick={() => {
                const signupadm = document.querySelector(".signup-admin");
                signupadm.classList.remove("hide");
              }}
            >
              Create Account
            </button>
          </div>
          {process.env.REACT_APP_HIRING_MODULE_ACTIVATED === "TRUE" && < div
            style={{
              width: "clamp(300px, 30vw, 400px)",
              backgroundColor: "rgb(255 255 255)",
              borderRadius: "10px",
              height: "266px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "17px",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"

            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "600"
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  padding: "5px 10px",
                  color: "hsl(0% 0% 20%)",
                  // backgroundColor: "#E2E2E2",
                  // borderRadius: "50px",
                  marginBottom: "20px"
                }}
              >
                Are you a Startup/Company?
              </span>
              <span style={{ color: "hsl(0%, 0%, 15%)", padding: "0px 20px" }}>Hiring Full Time Employees and/or Interns?</span>
            </p>
            <button
              style={{ fontSize: "18px", marginTop: "20px" }}
              className="cust-btn create-account-btn"
              onClick={() => {
                const signupemployer = document.querySelector(".signup-employer");
                signupemployer.classList.remove("hide");
              }}
            >
              Create Account
            </button>
          </div>}
        </div>
      </div>
    </div >
  );
};

export default GetStarted;
