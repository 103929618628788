import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Card,
    CardContent,
    Button,
    Box,
    Tabs,
    Tab,
    IconButton,
    Link,
    Chip
} from '@mui/material';
import {
    Business,
    Email,
    Phone,
    Language,
    LinkedIn,
    Check,
    Close
} from '@mui/icons-material';
import axios from 'axios';

const EmployerRequest = ({ request, onApprove }) => (
    <Card sx={{ mb: 2, boxShadow: 2 }}>
        <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" component="h2">
                    <Business sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {request.companyName}
                </Typography>
                {!request.approved && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Check />}
                        onClick={() => onApprove(request._id)}
                    >
                        Approve Request
                    </Button>
                )}
                {request.approved && (
                    <Chip
                        label="Verified"
                        color="success"
                        icon={<Check />}
                    />
                )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body1">
                    <Email sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {request.email}
                </Typography>

                <Typography variant="body1">
                    <Phone sx={{ mr: 1, verticalAlign: 'middle' }} />
                    WhatsApp: {request.mobileWhatsApp}
                </Typography>

                {request.website && (
                    <Link href={request.website} target="_blank" rel="noopener noreferrer"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <Language sx={{ mr: 1 }} />
                        Website
                    </Link>
                )}

                {request.linkedInRep && (
                    <Link href={request.linkedInRep} target="_blank" rel="noopener noreferrer"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <LinkedIn sx={{ mr: 1 }} />
                        LinkedIn Profile
                    </Link>
                )}
            </Box>
        </CardContent>
    </Card>
);

function ApproveRequests() {
    const [currentTab, setCurrentTab] = useState(0);
    const [requests, setRequests] = useState([]);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER_URL;

    const handleApprove = async (requestId) => {
        // Here you would typically make an API call to update the status
        try {
            const response = await axios.post(`${BACKEND_URL}/super-user/updateRequest/${requestId}`)
            if (response) {
                console.log(response.data);

            }
            setRequests(requests.map(req =>
                req._id === requestId ? { ...req, approved: true } : req
            ));
        } catch (error) {
            console.log("Some error occured :", error);
        }

    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const [pendingRequests, setPendingRequests] = useState([]);
    const [approvedRequests, setApprovedRequests] = useState([]);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/super-user/getRequests`)
                if (response) {
                    console.log(response.data.requests);
                    setRequests(response.data.requests);
                }
            } catch (error) {
                console.log("Some error occured :", error);
            }

        }
        fetchRequests();
    }, [])

    useEffect(() => {

        setPendingRequests(requests.filter(req => !req.approved));
        setApprovedRequests(requests.filter(req => req.approved));
    }, [requests])

    return (
        <div>
            <AppBar position="static" sx={{ mb: 3 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Employer Requests
                    </Typography>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab label={`Pending (${pendingRequests.length})`} />
                        <Tab label={`Verified (${approvedRequests.length})`} />
                    </Tabs>
                </Toolbar>
            </AppBar>

            <Container maxWidth="md">
                {currentTab === 0 && (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 2 }}>Pending Requests</Typography>
                        {pendingRequests.length === 0 ? (
                            <Typography>No pending requests</Typography>
                        ) : (
                            pendingRequests.map(request => (
                                <EmployerRequest
                                    key={request._id.$oid}
                                    request={request}
                                    onApprove={handleApprove}
                                />
                            ))
                        )}
                    </Box>
                )}

                {currentTab === 1 && (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 2 }}>Verified Requests</Typography>
                        {approvedRequests.length === 0 ? (
                            <Typography>No verified requests</Typography>
                        ) : (
                            approvedRequests.map(request => (
                                <EmployerRequest
                                    key={request._id}
                                    request={request}
                                />
                            ))
                        )}
                    </Box>
                )}
            </Container>
        </div>
    );
}

export default ApproveRequests;