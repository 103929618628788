import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Box,
    Link
} from '@mui/material';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import SignUpImage from "../../../images/Hiring Module/Sign Up.jpg";
import PhoneInput from 'react-phone-input-2';
import "./Register.css"
const EmployerRegister = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [timer, setTimer] = useState(45);
    const [canResendOTP, setCanResendOTP] = useState(false);
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const [generatedOTP, setGeneratedOTP] = useState('');
    const otpRefs = useRef([]);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        contactNumber: '',
        linkedinUrl: ''
    });
    const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER_URL;

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleOTPGeneration = async (email) => {
        try {
            const response = await fetch(`${BACKEND_URL}/emp/auth/newotp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    currentTime: new Date(),
                    email: email,
                })
            })

            const data = await response.json();
            console.log('Success:', data);
            return data;

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleOTPVerification = async (email, otpvalue) => {
        try {
            const response = await fetch(`${BACKEND_URL}/emp/auth/verifyotp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    currentTime: new Date(),
                    otpvalue: otpvalue,
                    email: email,
                })
            });

            const data = await response.json();
            console.log('Success:', data);
            return data;

        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    };

    useEffect(() => {
        let interval;
        if (step === 2 && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        }
        if (timer === 0) {
            setCanResendOTP(true);
        }
        return () => clearInterval(interval);
    }, [step, timer]);

    const handleChange = (e) => {

        if (!e.target) {
            setFormData(prev => ({
                ...prev,
                contactNumber: e
            }));
        } else {
            const { name, value } = e.target;
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
            setSuccessMessage(''); // Clear success message when user types
        }
    };
    const handleOTPChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) {
            const newOTP = [...otp];

            if (value.length === 1) {
                // Single digit input
                newOTP[index] = value;
                setOTP(newOTP);

                if (index < 5) {
                    otpRefs.current[index + 1].focus();
                }
            } else if (value.length > 1) {
                // Handle pasting multiple digits
                const pastedDigits = value.split('').slice(0, 6);
                const updatedOTP = [...otp];

                pastedDigits.forEach((digit, idx) => {
                    if (index + idx < 6) {
                        updatedOTP[index + idx] = digit;
                    }
                });

                setOTP(updatedOTP);

                // Focus on the next empty input or the last input
                const nextEmptyIndex = updatedOTP.findIndex((digit, idx) => !digit && idx > index);
                if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
                    otpRefs.current[nextEmptyIndex].focus();
                } else {
                    otpRefs.current[5].focus();
                }
            }
        }
        setErrors({})
    };

    const handleOTPKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior

            const newOTP = [...otp];

            // If current field has a value, clear it
            if (newOTP[index]) {
                newOTP[index] = '';
                setOTP(newOTP);
            }
            // If current field is empty and not the first field, move to previous field
            else if (index > 0) {
                newOTP[index - 1] = '';
                setOTP(newOTP);
                otpRefs.current[index - 1].focus();
            }
        } else if (e.key === 'Delete') {
            e.preventDefault();
            const newOTP = [...otp];
            newOTP[index] = '';
            setOTP(newOTP);
        } else if (e.key === 'ArrowLeft' && index > 0) {
            e.preventDefault();
            otpRefs.current[index - 1].focus();
        } else if (e.key === 'ArrowRight' && index < 5) {
            e.preventDefault();
            otpRefs.current[index + 1].focus();
        }
    };

    const handleOTPPaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const pastedDigits = pastedData.replace(/\D/g, '').split('').slice(0, 6);

        if (pastedDigits.length) {
            const newOTP = Array(6).fill(''); // Clear all fields first
            pastedDigits.forEach((digit, index) => {
                if (index < 6) {
                    newOTP[index] = digit;
                }
            });
            setOTP(newOTP);

            // Focus on the next empty input or the last input
            const nextEmptyIndex = newOTP.findIndex(digit => !digit);
            if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
                otpRefs.current[nextEmptyIndex].focus();
            } else {
                otpRefs.current[5].focus();
            }
        }
        setErrors({})

    };

    const handleNextStep = async () => {
        let hasErrors = false;
        const newErrors = {};

        if (step === 1) {
            if (!formData.email) {
                newErrors.email = 'Email is required';
                hasErrors = true;
            } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                newErrors.email = 'Invalid email format';
                hasErrors = true;
            }

            if (!hasErrors) {
                const response = await handleOTPGeneration(formData.email);
                if (response.success === true) {
                    setStep(2);
                    setTimer(45);
                    setCanResendOTP(false);
                }
                else if (response.message === 'Email already registered') {
                    setSuccessMessage('Email is already verified. Redirecting...');
                    setTimeout(() => {
                        setStep(3);
                    }, 3000)
                } else if (response.message === 'Email already registered and Registration Completed') {
                    setSuccessMessage('Email is already registered. Redirecting...');
                    setTimeout(() => {
                        navigate('/emp/login');
                    }, 3000);
                }
            }
        } else if (step === 2) {
            const otpValue = otp.join('');
            if (otpValue.length !== 6) {
                newErrors.otp = 'Please enter complete OTP';
                hasErrors = true;
            }

            if (!hasErrors) {
                const verified = await handleOTPVerification(formData.email, otpValue);
                console.log(verified, verified.success);
                if (verified && verified.success) {
                    setStep(3);
                } else {
                    newErrors.otp = verified.message;
                }
            }
        }

        setErrors(newErrors);
    };

    const handleResendOTP = () => {
        handleOTPGeneration(formData.email);
        setTimer(45);
        setCanResendOTP(false);
        setOTP(['', '', '', '', '', '']);
        setErrors({})

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!formData.password) newErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.contactNumber) newErrors.contactNumber = 'Contact number is required';
        if (formData.linkedinUrl && !/^https?:\/\/[a-zA-Z0-9-\.]+\.[a-zA-Z]{2,}/.test(formData.linkedinUrl)) {
            newErrors.linkedinUrl = 'Invalid URL format';
        }
        console.log(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const userData = {
                    email: formData.email,
                    password: formData.password,
                    fname: formData.firstName,
                    lname: formData.lastName,
                    mobNo: formData.contactNumber,
                    linkedinURL: formData.linkedinUrl
                }
                const response = await fetch(`${BACKEND_URL}/emp/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(userData)
                })
                const data = await response.json()

                console.log(data);

                if (response.ok) {
                    navigate('/emp/login');
                }

            } catch (error) {
                console.error('Registration error:', error);
            }
        } else {
            setErrors(newErrors);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <Typography variant="body1" sx={{ mb: 3, color: '#666', textAlign: 'center' }}>
                            Enter your email address to receive an OTP for verification
                        </Typography>
                        <TextField
                            fullWidth
                            label="Email Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            variant="outlined"
                            required
                            sx={{ mb: 1 }}
                        />
                        {successMessage && (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'success.main',
                                    mb: 2,
                                    textAlign: 'left',
                                    marginLeft: '14px'
                                }}
                            >
                                {successMessage}
                            </Typography>
                        )}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNextStep}
                            sx={{ mt: 1 }}
                        >
                            Next
                        </Button>
                    </>
                );

            // Rest of the steps remain the same...
            case 2:
            case 2:
                return (
                    <>
                        <Typography variant="body1" sx={{ mb: 3, color: '#666', textAlign: 'center' }}>
                            An OTP valid for 30 minutes has been sent to <br />{formData.email}.
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'center',
                            mb: 3
                        }}>
                            {otp.map((digit, index) => (
                                <TextField
                                    key={index}
                                    inputRef={el => otpRefs.current[index] = el}
                                    value={digit}
                                    onChange={(e) => handleOTPChange(e, index)}
                                    onKeyDown={(e) => handleOTPKeyDown(e, index)}
                                    onPaste={handleOTPPaste}
                                    variant="outlined"
                                    sx={{
                                        width: '3rem',
                                        '& input': { textAlign: 'center' }
                                    }}
                                    inputProps={{
                                        maxLength: 1,
                                        style: { padding: '1rem 0' }
                                    }}
                                />
                            ))}
                        </Box>
                        {errors.otp && (
                            <Typography color="error" sx={{ mb: 2, textAlign: 'center' }}>
                                {errors.otp}
                            </Typography>
                        )}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNextStep}
                            sx={{ mb: 2 }}
                        >
                            Verify OTP
                        </Button>
                        <Box sx={{ textAlign: 'center' }}>
                            {timer > 0 ? (
                                <Typography variant="body2" sx={{ color: '#666' }}>
                                    Resend OTP in {timer} seconds
                                </Typography>
                            ) : (
                                <Link
                                    component="button"
                                    onClick={handleResendOTP}
                                    sx={{
                                        color: 'primary.main',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Resend OTP
                                </Link>
                            )}
                        </Box>
                    </>
                );

            case 3:
                return (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Email Address"
                            value={formData.email}
                            disabled
                            sx={{ mb: 3 }}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                            required
                            sx={{ mb: 3 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VscEyeClosed /> : <VscEye />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            name="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            required
                            sx={{ mb: 3 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VscEyeClosed /> : <VscEye />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                            required
                            sx={{ mb: 3 }}
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                            required
                            sx={{ mb: 3 }}
                        />
                        <PhoneInput
                            country={'in'}
                            value={formData.phone}
                            onChange={handleChange}
                            inputClass="form-control"
                            containerClass="phone-input"
                            specialLabel="Contact Number"
                            enableSearch={true}
                            searchPlaceholder={'Search'}
                            inputProps={{
                                required: true,
                            }}
                            inputStyle={{
                                width: '100%',
                                height: '56px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: errors.phone ? '1px solid #d32f2f' : '1px solid rgba(0, 0, 0, 0.23)',
                                backgroundColor: 'transparent',
                                // paddingLeft: '48px',
                                paddingTop: '0',
                                paddingBottom: '0',
                                // marginBottom: '24px'
                            }}
                            buttonStyle={{
                                border: errors.phone ? '1px solid #d32f2f' : '1px solid rgba(0, 0, 0, 0.23)',
                                borderRight: 'none',
                                backgroundColor: 'transparent',
                                borderRadius: '4px 0 0 4px'
                            }}
                            containerStyle={{
                                width: '100%'
                            }}
                            dropdownClass={'mobile-dropdown-emp'}
                            labels={{ required: true }} // Shows the asterisk for required field
                        />
                        <TextField
                            fullWidth
                            label="LinkedIn URL"
                            name="linkedinUrl"
                            value={formData.linkedinUrl}
                            onChange={handleChange}
                            error={!!errors.linkedinUrl}
                            helperText={errors.linkedinUrl}
                            sx={{ mb: 3, mt: 3 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Register
                        </Button>
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="lg" sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
        }}>
            <Box sx={{
                width: '100%',
                maxWidth: '1200px',
                display: 'flex',
                backgroundColor: '#f8f9fa',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    <Box sx={{
                        width: { xs: '100%', md: '50%' }, padding: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        order: { xs: 1, md: 2 }
                    }}>
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: 'center',
                                marginBottom: '2rem',
                                fontWeight: 'bold',
                                color: '#1a1a1a'
                            }}
                        >
                            Sign Up
                        </Typography>

                        {renderStep()}

                        <Box sx={{ textAlign: 'center', marginTop: '1.5rem' }}>
                            <Typography variant="body2" sx={{ color: '#666' }}>
                                Already have an account?{' '}
                                <Link
                                    component="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/emp/login');
                                    }}
                                    sx={{
                                        color: 'primary.main',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Log In
                                </Link>
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '1px',
                        backgroundColor: '#e0e0e0',
                        order: { xs: 2, md: 3 }
                    }} />

                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2rem',
                        order: { xs: 3, md: 1 }
                    }}>
                        <img
                            src={SignUpImage}
                            alt="Sign Up illustration"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                maxWidth: '440px'
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default EmployerRegister;