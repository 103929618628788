import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import "./Login.css";
// import { GoogleLogin } from "react-google-login";
import { auth, googleProvider } from "../../firebase";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { MdEmail } from "react-icons/md";
import { TextField, Button } from "@mui/material";
// constants
import {
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
  INTERVIEWER_PROFILE_FORM_ROUTE,
  STUDENT_WELCOME_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
  STUDENT_DEACTIVATE_ROUTE,
  INTERVIEWER_DEACTIVATE_ROUTE,
} from "../../constants/routes";
import {
  AUTH_STD_GOOGLE_ENDPOINT,
  AUTH_INT_GOOGLE_ENDPOINT,
  AUTH_ADM_GOOGLE_ENDPOINT,
  AUTH_EMP_GOOGLE_ENDPOINT,
  AUTH_SINGLE_GMAIL_INSTITUTE,
  AUTH_CUSTOM_EMAIL,
  AUTH_CUSTOM_SIGNUP
} from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";
import { ShowChartOutlined } from "@mui/icons-material";

const remove = () => {
  const loginFunc = document.querySelector(".login-container");
  loginFunc.classList.add("hide");
  document.body.style.overflow = "auto";
};

const removeBack = (e) => {
  const loginFunc = document.querySelector(".login-container");
  document.body.style.overflow = "auto";
  loginFunc.classList.add("hide");
};
function childClick(event) {
  // console.log("Child clicked");
  event.stopPropagation(); // stop propagation to parent
}

const removePopup = () => {
  const loginFunc = document.querySelector(".login-popup");
  loginFunc.classList.add("hide");
  document.body.style.overflow = "auto";
};

const removeBackPopup = (e) => {
  const loginFunc = document.querySelector(".login-popup");
  document.body.style.overflow = "auto";
  loginFunc.classList.add("hide");
};

const Login = () => {
  const navigate = useNavigate(); // To route to another location
  //const history = useHistory(); // Tracks the routing history
  const { setUser } = useContext(UserContext);
  const [userData, setuserData] = useState({})
  const [customEmail, setCustomEmail] = useState("")
  const [customPassword, setCustomPassword] = useState("")
  const [customConfirmPassword, setCustomConfirmPassword] = useState("")
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  console.log("login reloaded...");

  // useEffect(() => {
  //   // window.location.reload(false);
  //   // axios.get("/get", getInterviewers)((res) => console.log("res: ", res));
  //   console.log("reloading...");
  // }, [navigate]);
  const handleEmpManual = (e) => {
    remove();

    e.preventDefault();
    navigate("/emp/login")
  }

  const handleAdmGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const iemail = res.user.email;
        const iname = "";
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        axios({
          method: "POST",
          url: AUTH_ADM_GOOGLE_ENDPOINT,
          data: { email_verified, iemail, iname, sub, picture },
        }).then((response) => {
          console.log("Google Login Success", response);
          setUser(response.data.user);
          localStorage.setItem(
            "isAuthenticated",
            JSON.stringify({ token: "" })
          )
          localStorage.setItem(
            "isIAuthenticated",
            JSON.stringify({ token: "" })
          )
          localStorage.setItem(
            "isAAuthenticated",
            JSON.stringify({ token: response.data.token })
          );
          remove();
          if (response.data.user.aVerified) {
            navigate("/admin/current-students");
          } else if (response.data.user.institute)
            navigate("/admin/profile-form");
          else {
            navigate("/admin/profile-form");
            setTimeout(() => {
              toast.success(`You Logged in Successfully`, {
                ...toastStyle.success,
              });
            }, 100);
            setTimeout(() => {
              toast.loading(`Please fill your profile details`, {
                ...toastStyle.loading,
                duration: 3500,
              });
            }, 2500);
          }
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };



  const handleEmpGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const iemail = res.user.email;
        const iname = "";
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        axios({
          method: "POST",
          url: AUTH_EMP_GOOGLE_ENDPOINT,
          data: { email_verified, iemail, iname, sub, picture },
        }).then((response) => {
          console.log("Google Login Success", response);
          setUser(response.data.user);
          localStorage.setItem(
            "isAuthenticated",
            JSON.stringify({ token: "" })
          )
          localStorage.setItem(
            "isIAuthenticated",
            JSON.stringify({ token: "" })
          )
          localStorage.setItem(
            "isAAuthenticated",
            JSON.stringify({ token: response.data.token })
          );
          remove();
          if (response.data.user.aVerified) {
            navigate("/admin/current-students");
          } else if (response.data.user.institute)
            navigate("/admin/profile-form");
          else {
            navigate("/admin/profile-form");
            setTimeout(() => {
              toast.success(`You Logged in Successfully`, {
                ...toastStyle.success,
              });
            }, 100);
            setTimeout(() => {
              toast.loading(`Please fill your profile details`, {
                ...toastStyle.loading,
                duration: 3500,
              });
            }, 2500);
          }
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };


  const handleStdGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then(async (res) => {
        const email_verified = res.user.emailVerified;
        const email = res.user.email;
        const name = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        setuserData({ email_verified, email, name, sub, picture });
        console.log("res: ", res);

        const result = await axios.post(AUTH_SINGLE_GMAIL_INSTITUTE, { singleGmail: email })
          .catch((err) => {
            console.log(err)
          })

        if (result?.data) {
          setuserData({
            email_verified,
            picture,
            name: "",
            email: document.getElementById("customEmail").value,
            password: document.getElementById("customPassword").value
          })
          remove();
          const loginFunc = document.querySelector(".login-popup");
          loginFunc.classList.remove("hide");
          document.body.style.overflow = "hidden";
          setCustomEmail(document.getElementById("customEmail").value)
          setCustomPassword(document.getElementById("customPassword").value)

          return;
        }
        try {
          axios({
            method: "POST",
            url: AUTH_STD_GOOGLE_ENDPOINT,
            data: { email_verified, email, name, sub, picture },
          })
            .then((response) => {
              console.log("Google Login Success", response.data);
              setUser({
                ...response.data.user,
                photo:
                  // process.env.REACT_APP_BACKEND_SERVER_URL +
                  // "/uploads/" +
                  response.data.user.photo,
              });
              localStorage.setItem(
                "isIAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isAAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isAuthenticated",
                JSON.stringify({ token: response.data.token })
              );
              remove();
              if (response.data.deactivated) {
                navigate(STUDENT_DEACTIVATE_ROUTE);
                return;
              } else if (
                response.data.user.mobNumber &&
                !response.data.deactivated
              )
                navigate(STUDENT_FIND_AN_INTERVIEWER_ROUTE);
              else {
                navigate(STUDENT_PROFILE_FORM_ROUTE);
                setTimeout(() => {
                  toast.success(`You Logged in Successfully`, {
                    ...toastStyle.success,
                  });
                }, 100);
                setTimeout(() => {
                  toast.loading(`Please fill your profile details`, {
                    ...toastStyle.loading,
                    duration: 3500,
                  });
                }, 2500);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleCustomLogin = async (e) => {
    e.preventDefault();
    if (showConfirmPassword) {
      handleCustomSignup(e);
    }
    else {
      try {
        const result = await axios.post(AUTH_CUSTOM_EMAIL, { customEmail, customPassword })
          .catch((err) => {
            console.log(err)
          })
        console.log(customEmail)
        console.log(result)
        if (result?.data?.message === "") {
          console.log(userData)
          axios({
            method: "POST",
            url: AUTH_STD_GOOGLE_ENDPOINT,
            data: { ...userData, name: "  " },
          })
            .then((response) => {
              console.log("Google Login Success", response.data);
              setUser({
                ...response.data.user,
                photo:
                  // process.env.REACT_APP_BACKEND_SERVER_URL +
                  // "/uploads/" +
                  response.data.user.photo,
              });
              localStorage.setItem(
                "isIAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isAAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isAuthenticated",
                JSON.stringify({ token: response.data.token })
              );
              removePopup();
              if (response.data.deactivated) {
                navigate(STUDENT_DEACTIVATE_ROUTE);
                return;
              } else if (
                response.data.user.mobNumber &&
                !response.data.deactivated
              )
                navigate(STUDENT_FIND_AN_INTERVIEWER_ROUTE);
              else {
                navigate(STUDENT_PROFILE_FORM_ROUTE);
                setTimeout(() => {
                  toast.success(`You Logged in Successfully`, {
                    ...toastStyle.success,
                  });
                }, 100);
                setTimeout(() => {
                  toast.loading(`Please fill your profile details`, {
                    ...toastStyle.loading,
                    duration: 3500,
                  });
                }, 2500);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
        else {
          toast.error(result?.data?.message, {
            ...toastStyle.error,
            duration: 5000
          })
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleIntGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const email = res.user.email;
        const name = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        try {
          axios
            .post(AUTH_INT_GOOGLE_ENDPOINT, {
              email_verified,
              email,
              name,
              sub,
              picture,
            })
            .then((response) => {
              console.log("Google Login Success", response);
              setUser({
                ...response.data.user,
                photo:
                  // process.env.REACT_APP_BACKEND_SERVER_URL +
                  // "/uploads/" +
                  response.data.user.photo,
              });
              localStorage.setItem(
                "isAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isAAuthenticated",
                JSON.stringify({ token: "" })
              );
              localStorage.setItem(
                "isIAuthenticated",
                JSON.stringify({ token: response.data.token })
              );
              remove();
              if (response.data.deactivated) {
                navigate(INTERVIEWER_DEACTIVATE_ROUTE);
                return;
              } else if (response.data.user.designation)
                navigate(INTERVIEWER_RECIEVED_REQUESTS_ROUTE);
              else {
                navigate(INTERVIEWER_PROFILE_FORM_ROUTE);
                setTimeout(() => {
                  toast.success(`You Logged in Successfully`, {
                    ...toastStyle.success,
                  });
                }, 100);
                setTimeout(() => {
                  toast.loading(`Please fill your profile details`, {
                    ...toastStyle.loading,
                    duration: 3500,
                  });
                }, 2500);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCustomEmail = (e) => {
    setuserData({
      ...userData,
      email: e.target.value
    })
    setCustomEmail(e.target.value)
  }

  const handleCustomPassword = (e) => {
    setuserData({
      ...userData,
      password: e.target.value
    })
    setCustomPassword(e.target.value)
  }

  const handleCustomConfirmPassword = (e) => {
    setuserData({
      ...userData,
      confirmPassword: e.target.value
    })
    setCustomConfirmPassword(e.target.value)
  }

  const handleCustomSignup = async (e) => {
    try {
      if (customPassword !== customConfirmPassword) {
        toast.error("Password do not match", {
          ...toastStyle.error,
          duration: 3000
        })
        return;
      }
      const result = await axios.post(AUTH_CUSTOM_SIGNUP, { customEmail, customPassword })
        .catch((err) => {
          console.log("Error", err);
        })
      if (result?.data?.message === "") {
        toast.success("Registered successfully", {
          ...toastStyle.success,
          duration: 2000
        })
        setShowConfirmPassword(!showConfirmPassword)
      }
      else if (result?.data?.message) {
        toast.error(result?.data?.message, {
          ...toastStyle.error,
          duration: 3000
        })
        setShowConfirmPassword(!showConfirmPassword)
      }
      else {
        console.log(result)
      }
    } catch (err) {
      console.log("Error", err);
    }
  }

  return (
    // <motion.div
    //   initial={{ y: 10, opacity: 0 }}
    //   animate={{ y: 0, opacity: 1 }}
    //   exit={{ y: -10, opacity: 0 }}
    //   transition={{ duration: 2 }}
    // >
    <>
      <div
        style={{ backdropFilter: "blur(10px)", paddingTop: "70px" }}
        className="login-container hide"
        onClick={(e) => {
          removeBack(e);
        }}
      >
        <div className="login" style={{ minHeight: "40vh" }} onClick={(e) => childClick(e)}>
          <div
            className="cross"
            onClick={() => {
              remove();
            }}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </div>
          <div className="login-head">
            <p style={{ fontSize: "20px" }}>
              Let's experience the Mock Interviews with Realtime Feedback!
            </p>
          </div>
          <div className="login-system" style={{ display: "flex", flexWrap: "wrap", maxWidth: "900px", width: "90vw", minHeight: "30vh", gap: "1.5rem", justifyContent: "center", alignItems: "center" }}>
            <div className="col">
              <h1 style={{ marginBottom: "0px", padding: "5px 0px" }}>Log In as a Student</h1>
              <h5 style={{ textAlign: "center", marginBottom: "13px" }}>
                Be an Interviewee!{" "}
              </h5>
              {/* <GoogleLogin
              className="signin-btn login"
              clientId="752367686296-0scm5me6nrjuc64f9dc36i88c7mstjrt.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseSuccessGoogleStd}
              onFailure={responseErrorGoogleStd}
              cookiePolicy={"single_host_origin"}
            /> */}
              <form onSubmit={(e) => handleStdGoogle(e)}>
                <button className="signin-btn login" type="submit">
                  <img src={require("../../images/google.png")} alt="Google" />
                  Log In with Google
                </button>
              </form>
              {/* <form onClick={(e) => handleStdFacebook(e)}>
              <button className="signin-btn login" type="submit">
                <img
                  src={require("../../images/facebook.png")}
                  alt="Facebook"
                />
                Sign In with Facebook
              </button>
            </form>
            <form action={AUTH_STD_LINKEDIN_ENDPOINT}>
              <button className="signin-btn login" type="submit">
                <img
                  src={require("../../images/linkedin.png")}
                  alt="Linkedin"
                />
                Sign In with Linkedin
              </button>
            </form> */}
            </div>
            {/* <hr
              style={{
                height: "180px",
                width: "1px",
                backgroundColor: "black",
              }}
            /> */}
            <div className="col">
              <h1 style={{ marginBottom: "0px", padding: "5px 0px" }}>Log In as a Professional</h1>
              <h5 style={{ textAlign: "center", marginBottom: "13px" }}>
                Be an Interviewer!{" "}
              </h5>

              <form onSubmit={(e) => handleIntGoogle(e)}>
                <button className="signin-btn login" type="submit">
                  <img src={require("../../images/google.png")} alt="Google" />
                  Log In with Google
                </button>
              </form>
            </div>
            {/* <hr
              style={{
                height: "180px",
                width: "1px",
                backgroundColor: "black",
              }}
            /> */}
            <div className="col">
              <h1 style={{ marginBottom: "0px", padding: "5px 0px" }}>
                Log In as an Institute Admin
              </h1>
              <h5 style={{ textAlign: "center", marginBottom: "13px" }}>
                Be a Facilitator!{" "}
              </h5>

              <form onSubmit={(e) => handleAdmGoogle(e)}>
                <button className="signin-btn login" type="submit">
                  <img src={require("../../images/google.png")} alt="Google" />
                  Log In with Google
                </button>
              </form>
            </div>
            {process.env.REACT_APP_HIRING_MODULE_ACTIVATED === "TRUE" && <>
              <div className="col">
                <h1 style={{ marginBottom: "0px", padding: "5px 0px" }}>
                  Log In as an Employer
                </h1>
                {/* <h5 style={{ textAlign: "center", marginBottom: "13px" }}>
                  Guide Change, Facilitate Growth!{" "}
                </h5> */}

                <form onSubmit={(e) => handleAdmGoogle(e)}>
                  <button className="signin-btn login" type="submit">
                    <img src={require("../../images/google.png")} alt="Google" />
                    Log In with Google
                  </button>
                </form>
                <form onSubmit={(e) => handleEmpManual(e)}>
                  <button className="signin-btn login" type="submit">
                    {/* <img src={require("../../images/google.png")} alt="Google" /> */}
                    <i style={{ marginRight: "10px", marginLeft: "-10px", fontSize: "18px", color: "gray" }}><MdEmail /></i>
                    Log In with Email
                  </button>
                </form>
              </div>
            </>
            }

          </div>
        </div>
      </div>
      <div
        style={{ backdropFilter: "blur(10px)", paddingTop: "70px" }}
        className="login-popup hide"
        onClick={(e) => {
          removeBackPopup(e);
        }}
      >
        <div className="login" style={{ minHeight: "40vh" }} onClick={(e) => childClick(e)}>
          <div
            className="cross"
            onClick={() => {
              removePopup();
            }}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </div>
          <div className="login-head">
            <p style={{ fontSize: "20px" }}>
              Let's experience the Mock Interviews with Realtime Feedback!
            </p>
          </div>
          <div className="login-system" style={{ display: "flex", flexWrap: "wrap", maxWidth: "900px", width: "90vw", minHeight: "30vh", gap: "1.5rem", justifyContent: "center", alignItems: "center" }}>
            <div className="col">
              <form onSubmit={handleCustomLogin}>
                <TextField
                  id="customEmail"
                  label="Email"
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  onChange={handleCustomEmail}
                  value={customEmail}
                />
                <TextField
                  id="customPassword"
                  label="Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  onChange={handleCustomPassword}
                  value={customPassword}
                />
                {showConfirmPassword && <TextField
                  id="customConfirmPassword"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  onChange={handleCustomConfirmPassword}
                  value={customConfirmPassword}
                />}
                <div>
                  {showConfirmPassword ? "Already have an account?" : "Don't have an account?"}
                  <Button size="small" href="#" color="primary" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}>{showConfirmPassword ? "Log In" : "Sign Up"}</Button>
                </div>
                <button className="signin-btn login" type="submit">
                  {!showConfirmPassword ? "Log In" : "Sign Up"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
    // </motion.div>
  );
};

export default Login;
