import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Typography,
    Select,
    MenuItem,
    Button,
    FormHelperText
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ProfileForm.css'
import UserContext from '../../../contexts/UserContext';
import axios from 'axios';

// Assuming NavbarLoggedIn component exists
// import NavbarLoggedIn from '../../Dashboard/NavbarLoggedIn';

const ProfileForm = () => {
    const [formData, setFormData] = useState({
        logo: '',
        title: '',
        companyName: '',
        cin: '',
        buildingName: '',
        locality: '',
        cityState: '',
        pinCode: '',
        officialEmail: '',
        whatsappNumber: '',
        officialPhone: '',
        adminTitle: '',
        adminFirstName: '',
        adminLastName: '',
        adminPosition: '',
        adminEmail: '',
        adminMobile: '',
        websiteUrl: '',
        linkedinUrl: ''
    });

    const [errors, setErrors] = useState({});
    const [logoFile, setLogoFile] = useState(null);
    const { user, setUser } = useContext(UserContext)
    useEffect(() => {

        if (user.role === "Employer") {
            setFormData((prevItems) => ({ ...prevItems, adminEmail: user.email, adminFirstName: user.fname, adminLastName: user.lname, adminMobile: user.mobNo, linkedinUrl: user.linkedinURL }));
        }
        console.log("*******");

    }, [user])
    const handleLogoUpload = (file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            setErrors((prev) => ({
                ...prev,
                logo: 'File size should not exceed 5MB',
            }));
            return;
        }

        // Create a FileReader instance
        const reader = new FileReader();

        // Set up the onload event to store the base64 string when the file is read
        reader.onload = (event) => {
            const base64String = event.target.result; // This is the base64 string
            setFormData((prev) => ({
                ...prev,
                logo: base64String, // Update the logo field with the base64 string
            })); // Store the base64 string
            setErrors((prev) => ({ ...prev, logo: null }));
        };

        // Read the file as a data URL (base64 encoded string)
        reader.readAsDataURL(file);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedFormData = { ...formData, adminEmail: user.email };
        console.log(updatedFormData);

        // Overwrite adminEmail to ensure it is always user.email
        setFormData(updatedFormData);

        axios.post("http://localhost:8585/emp/update-profile", updatedFormData).then((response) => {
            console.log(response.data);

        }).catch(error => {
            console.log(error);

        });
    };


    return (
        <>
            <Helmet>
                <title>Company Profile Form</title>
                <meta name="description" content="Create your company profile" />
            </Helmet>

            {/* <NavbarLoggedIn /> */}

            <div className="profile-form-wrapper">
                <h1>Company Profile Setup</h1>
                <h2>Tell us about your company!</h2>

                <form onSubmit={handleSubmit}>
                    <div className="profile-form-cont">
                        {/* Logo Upload Section */}
                        <div className="profile-form-text">
                            <div className="profile-photo">
                                <Typography variant="h6">Company Logo</Typography>
                                {logoFile && (
                                    <img
                                        src={URL.createObjectURL(logoFile)}
                                        alt="Company Logo"
                                        className="preview-logo"
                                    />
                                )}
                                <div className="filebase">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleLogoUpload(e.target.files[0])}
                                    />
                                </div>
                                {errors.logo && <p className="choosefile error">{errors.logo}</p>}
                            </div>

                            {/* Company Basic Info */}
                            <div className="name cust-row">
                                <div className="element">
                                    <label>Company/Startup Name*</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="element">
                                    <label>CIN*</label>
                                    <input
                                        type="text"
                                        name="cin"
                                        value={formData.cin}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Address Section */}
                            <div className="address cust-row">
                                <div className="element">
                                    <label>Building Number/Name*</label>
                                    <input
                                        type="text"
                                        name="buildingName"
                                        value={formData.buildingName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="element">
                                    <label>Locality*</label>
                                    <input
                                        type="text"
                                        name="locality"
                                        value={formData.locality}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="address cust-row">
                                <div className="element">
                                    <label>City and State*</label>
                                    <input
                                        type="text"
                                        name="cityState"
                                        value={formData.cityState}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="element">
                                    <label>PIN Code*</label>
                                    <input
                                        type="text"
                                        name="pinCode"
                                        value={formData.pinCode}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Contact Information */}
                            <div className="contact cust-row">
                                <div className="element">
                                    <label>Official Email*</label>
                                    <input
                                        type="email"
                                        name="officialEmail"
                                        value={formData.officialEmail}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="element">
                                    <label>Official Phone*</label>
                                    <input
                                        type="tel"
                                        name="officialPhone"
                                        value={formData.officialPhone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Admin Details */}
                            <div className="admin-info">
                                <Typography variant="h6">Admin Details</Typography>
                                <div className="name cust-row">
                                    <div className="element">
                                        <label>Title*</label>
                                        <Select
                                            value={formData.adminTitle}
                                            name="adminTitle"
                                            onChange={handleInputChange}
                                            required
                                            fullWidth
                                        >
                                            <MenuItem value="Mr.">Mr.</MenuItem>
                                            <MenuItem value="Ms.">Ms.</MenuItem>
                                            <MenuItem value="Mrs.">Mrs.</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="element">
                                        <label>First Name*</label>
                                        <input
                                            type="text"
                                            name="adminFirstName"
                                            value={formData.adminFirstName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="element">
                                        <label>Last Name*</label>
                                        <input
                                            type="text"
                                            name="adminLastName"
                                            value={formData.adminLastName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="contact cust-row">
                                    <div className="element">
                                        <label>Position/Designation*</label>
                                        <input
                                            type="text"
                                            name="adminPosition"
                                            value={formData.adminPosition}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="element">
                                        <label>Email*</label>
                                        <input
                                            type="email"
                                            disabled={true}
                                            name="adminEmail"
                                            value={formData.adminEmail}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="contact cust-row">
                                    <div className="element">
                                        <label>Mobile/WhatsApp*</label>
                                        <PhoneInput
                                            country={'in'}
                                            value={formData.adminMobile}
                                            onChange={(phone) => setFormData(prev => ({
                                                ...prev,
                                                adminMobile: phone
                                            }))}
                                            inputClass="form-control"
                                            containerClass="phone-input"
                                            specialLabel="Contact Number"
                                            enableSearch={true}
                                            searchPlaceholder={'Search'}
                                            inputProps={{
                                                required: true,
                                            }}
                                            inputStyle={{
                                                width: '100%',
                                                height: '56px',
                                                fontSize: '16px',
                                                borderRadius: '4px',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                backgroundColor: 'transparent',
                                                // paddingLeft: '48px',
                                                paddingTop: '0',
                                                paddingBottom: '0',
                                                // marginBottom: '24px'
                                            }}
                                            buttonStyle={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderRight: 'none',
                                                backgroundColor: 'transparent',
                                                borderRadius: '4px 0 0 4px'
                                            }}
                                            containerStyle={{
                                                width: '100%'
                                            }}
                                            dropdownClass={'mobile-dropdown-emp'}
                                            labels={{ required: true }} // Shows the asterisk for required field
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* URLs */}
                            <div className="urls cust-row">
                                <div className="element">
                                    <label>Website URL</label>
                                    <input
                                        type="url"
                                        name="websiteUrl"
                                        value={formData.websiteUrl}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="element">
                                    <label>LinkedIn URL</label>
                                    <input
                                        type="url"
                                        name="linkedinUrl"
                                        value={formData.linkedinUrl}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="submit-button"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </>
    );
};

export default ProfileForm;