import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Paper,
    Typography,
    TextField,
    MenuItem,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Select,
    Chip,
    OutlinedInput,
    Checkbox,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Snackbar
} from '@mui/material';
import {
    InfoOutlined,
    Preview,
    Edit,
    Delete,
    CloudUpload
} from '@mui/icons-material';

const CreateJobPost = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        positionName: '',
        coreProfiles: [],
        nonCoreProfiles: [],
        description: '',
        positionType: '',
        numberOfPositions: '',
        workLocationType: '',
        workAddress: {
            useCompanyAddress: true,
            customAddress: ''
        },
        hybridDetails: {
            officeDays: '',
            homeDays: ''
        },
        compensation: {
            amount: '',
            currency: 'INR'
        },
        additionalBenefits: '',
        timeframe: '',
        internshipDuration: {
            length: '',
            unit: 'months'
        },
        workingHours: '',
        jobDescription: null
    });

    const [openPreview, setOpenPreview] = useState(false);
    const [openCoupon, setOpenCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [finalAmount, setFinalAmount] = useState(1000);
    const [paymentCompleted, setPaymentCompleted] = useState(false);

    // Sample data - Replace with your actual data
    const coreProfiles = ['Software Development', 'Data Science', 'Product Management'];
    const nonCoreProfiles = ['Marketing', 'Sales', 'HR'];
    const positionTypes = ['Full Time', 'Internship'];
    const locationTypes = ['Onsite', 'Remote', 'Hybrid'];

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleProfileSelect = (event, type) => {
        const { value } = event.target;
        setFormData(prev => ({
            ...prev,
            [type]: value
        }));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFormData(prev => ({
            ...prev,
            jobDescription: file
        }));
    };

    const handlePreview = () => {
        setOpenPreview(true);
    };

    const handlePayment = async () => {
        // Integrate with Razorpay here
        try {
            // Simulating payment success
            setPaymentCompleted(true);
        } catch (error) {
            console.error('Payment failed:', error);
        }
    };

    const handleSubmit = async () => {
        // Add your submission logic here
        navigate('/employer/active-jobs');
    };

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, my: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Create a Job Post
                </Typography>

                {/* Position Details */}
                <Box mb={3}>
                    <TextField
                        fullWidth
                        label="Name of Position"
                        value={formData.positionName}
                        onChange={(e) => handleInputChange('positionName', e.target.value)}
                        margin="normal"
                    />

                    <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" gutterBottom>
                            Profile Selection
                        </Typography>
                        <Select
                            multiple
                            value={formData.coreProfiles}
                            onChange={(e) => handleProfileSelect(e, 'coreProfiles')}
                            input={<OutlinedInput />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {coreProfiles.map((profile) => (
                                <MenuItem key={profile} value={profile}>
                                    <Checkbox checked={formData.coreProfiles.indexOf(profile) > -1} />
                                    {profile}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Brief Description"
                        value={formData.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        margin="normal"
                        helperText="Maximum 30 words"
                    />
                </Box>

                {/* Position Type and Location */}
                <Box mb={3}>
                    <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" gutterBottom>
                            Position Type
                        </Typography>
                        <Select
                            value={formData.positionType}
                            onChange={(e) => handleInputChange('positionType', e.target.value)}
                        >
                            {positionTypes.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" gutterBottom>
                            Work Location
                        </Typography>
                        <Select
                            value={formData.workLocationType}
                            onChange={(e) => handleInputChange('workLocationType', e.target.value)}
                        >
                            {locationTypes.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {formData.workLocationType === 'Hybrid' && (
                        <Box mt={2}>
                            <TextField
                                type="number"
                                label="Office Days per Week"
                                value={formData.hybridDetails.officeDays}
                                onChange={(e) => handleInputChange('hybridDetails', {
                                    ...formData.hybridDetails,
                                    officeDays: e.target.value
                                })}
                                margin="normal"
                                sx={{ mr: 2 }}
                            />
                            <TextField
                                type="number"
                                label="Home Days per Week"
                                value={formData.hybridDetails.homeDays}
                                onChange={(e) => handleInputChange('hybridDetails', {
                                    ...formData.hybridDetails,
                                    homeDays: e.target.value
                                })}
                                margin="normal"
                            />
                        </Box>
                    )}
                </Box>

                {/* Compensation */}
                <Box mb={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        {formData.positionType === 'Full Time' ? 'Annual CTC' : 'Monthly Stipend'}
                        <Tooltip title="Additional benefits like ESOPs, Profit Share, etc.">
                            <IconButton size="small">
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <TextField
                        type="number"
                        fullWidth
                        value={formData.compensation.amount}
                        onChange={(e) => handleInputChange('compensation', {
                            ...formData.compensation,
                            amount: e.target.value
                        })}
                        InputProps={{
                            startAdornment: (
                                <Select
                                    value={formData.compensation.currency}
                                    onChange={(e) => handleInputChange('compensation', {
                                        ...formData.compensation,
                                        currency: e.target.value
                                    })}
                                    sx={{ mr: 1 }}
                                >
                                    <MenuItem value="INR">INR</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                </Select>
                            )
                        }}
                    />
                </Box>

                {/* JD Upload */}
                <Box mb={3}>
                    <Button
                        variant="outlined"
                        component="label"
                        startIcon={<CloudUpload />}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Upload Job Description
                        <input
                            type="file"
                            hidden
                            accept=".pdf,.doc,.docx"
                            onChange={handleFileUpload}
                        />
                    </Button>
                    {formData.jobDescription && (
                        <Typography variant="caption" display="block" gutterBottom>
                            Selected file: {formData.jobDescription.name}
                        </Typography>
                    )}
                </Box>

                {/* Action Buttons */}
                <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button
                        variant="outlined"
                        startIcon={<Preview />}
                        onClick={handlePreview}
                    >
                        Preview
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!paymentCompleted}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Paper>

            {/* Preview Dialog */}
            <Dialog
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Preview Job Post
                    <IconButton
                        edge="end"
                        onClick={() => setOpenPreview(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Edit />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Add preview content here */}
                    <Typography variant="h6" gutterBottom>
                        Position: {formData.positionName}
                    </Typography>
                    {/* Add more preview content */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPreview(false)}>Close</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePayment}
                        disabled={paymentCompleted}
                    >
                        Pay Now (₹{finalAmount})
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Coupon Dialog */}
            <Dialog
                open={openCoupon}
                onClose={() => setOpenCoupon(false)}
            >
                <DialogTitle>Apply Coupon</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Coupon Code"
                        fullWidth
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCoupon(false)}>Cancel</Button>
                    <Button onClick={() => {
                        // Add coupon validation logic
                        setOpenCoupon(false);
                    }}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default CreateJobPost;