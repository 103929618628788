import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Box,
    Link
} from '@mui/material';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import LoginImage from "../../../images/Hiring Module/Log In.jpg"

const EmployerLogin = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    });
    const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER_URL;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({ email: '', password: '' });

        try {
            const response = await fetch(`${BACKEND_URL}/emp/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            switch (data.message) {
                case 'INVALID_EMAIL':
                    setErrors(prev => ({
                        ...prev,
                        email: 'Email is not registered'
                    }));
                    break;
                case 'INCORRECT_PASSWORD':
                    setErrors(prev => ({
                        ...prev,
                        password: 'Incorrect password'
                    }));
                    break;
                case 'SUCCESS':

                    localStorage.setItem("isEAuthenticated", JSON.stringify({ token: data.token }))
                    if (data.companyVerifiedStatus !== "verified")
                        window.location.href = "/emp/verify";
                    else
                        window.location.href = "/emp/create-job";
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <Container maxWidth="lg" sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
        }}>
            <Box sx={{
                width: '100%',
                maxWidth: '1200px',
                display: 'flex',
                backgroundColor: '#f8f9fa',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>
                {/* Flex container for image and form */}
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    {/* Image Container */}
                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2rem'
                    }}>
                        <img
                            src={LoginImage}
                            alt="Login illustration"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                maxWidth: '400px'
                            }}
                        />
                    </Box>

                    {/* Vertical Divider */}
                    <Box sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '1px',
                        backgroundColor: '#e0e0e0'
                    }} />

                    {/* Login Form Container */}
                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        padding: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: 'center',
                                marginBottom: '2rem',
                                fontWeight: 'bold',
                                color: '#1a1a1a'
                            }}
                        >
                            Employer Login
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Email Address"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                variant="outlined"
                                required
                                sx={{ marginBottom: '1.5rem' }}
                            />

                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleChange}
                                error={!!errors.password}
                                helperText={errors.password}
                                variant="outlined"
                                required
                                sx={{ marginBottom: '1.5rem' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VscEyeClosed /> : <VscEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ marginTop: '1rem' }}
                            >
                                Login
                            </Button>

                            <Box sx={{ textAlign: 'center', marginTop: '1.5rem' }}>
                                <Typography variant="body2" sx={{ color: '#666' }}>
                                    Don't have an account?{' '}
                                    <Link
                                        component="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/emp/register');
                                        }}
                                        sx={{
                                            color: 'primary.main',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        Register
                                    </Link>
                                </Typography>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default EmployerLogin;